import axios from 'axios';

export class HttpClient {
  constructor () {
    const instance = axios.create();
    this.instance = instance;
    this.baseURL = process.env.VUE_APP_BE_API_URL;
    this.instance.defaults.withCredentials = true;
    this.instance.interceptors.request.use(
      request => request,
      error => Promise.reject(error)
    );
    this.instance.interceptors.response.use(
      response => response,
      error => {
        if (error && error.status === 401 || error.status === 419 ) {
        }
        return Promise.reject(error);
      },
    );

  }
  async getAsync (url, params = null) {
    return this.instance.get(this.baseURL + url, params);
  }

  async postAsync (url, body = null, params = null) {
    return this.instance.post(url, body, params);
  }

  async putAsync (url, body = null, params = null) {
    return this.instance.put(url, body, params);
  }

  async patchAsync (url, body = null, params = null) {
    return this.instance.patch(url, body, params);
  }

  async deleteAsync (url, body = null, params = null) {
    return this.instance.delete(url, body, params);
  }
}
